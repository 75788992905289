export const paths = {
  home: "/",
  books: "/books",
  book: "/book/:slug",
  comingSoon: "/coming-soon",
  about: "/about",
  subscribe: "/subscribe",
  subscribeWithGift: "/subscribe-with-gift",
  privacyPolicy: "/privacy-policy",
  terms: "/terms",
  cookiePolicy: "/cookie-policy",
};

export const menuPaths = [
  {
    path: paths.home,
    display: "Home",
  },
  {
    path: paths.books,
    display: "Books",
  },
  {
    path: paths.about,
    display: "About",
  },
  // {
  //   path: paths.subscribe,
  //   display: "Subscribe",
  // },
];
