import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { paths, menuPaths } from "../paths";
import { siteConfig } from "../../site-config";

export const NavigationBar: React.FC = () => (
  <div className="navigation">
    <div className="navigation__author-name">
      <Link className="navigation__author-name-link" to={paths.home}>
        {siteConfig.owner}
      </Link>
    </div>
    <input className="navigation__checkbox" id="navi-toggle" type="checkbox" />
    <label className="navigation__button" htmlFor="navi-toggle">
      <div className="navigation__icon"></div>
    </label>
    <nav className="navigation__nav">
      <ul className="navigation__list">
        {menuPaths.map((entry) => (
          <li key={entry.path} className="navigation__item">
            <NavLink
              className={({ isActive }) =>
                "navigation__link" + (isActive ? " is-active" : "")
              }
              end
              to={entry.path}
            >
              {entry.display}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  </div>
);

export default NavigationBar;
