import {
  BookDetails,
  BookFormatDetails,
  ActionDetails,
  ActionType,
  BookFormat,
} from "../lib/types/website-types";
import { ReleaseStage } from "../lib/types/source-data";

const findFormatAction = ({
  format,
}: {
  format: BookFormat;
}): ActionDetails => {
  if (!format.isVisible) {
    return { actionType: ActionType.NO_ACTION };
  }
  switch (format.releaseStage) {
    case ReleaseStage.PUBLISHED:
      return { actionType: ActionType.BUY, actionLink: format.universalLink };
    case ReleaseStage.ON_PREORDER:
      return {
        actionType: ActionType.PREORDER,
        actionLink: format.universalLink,
      };
    case ReleaseStage.COMING_SOON:
      return { actionType: ActionType.NO_ACTION };
  }
  if (format.isGift) {
    return {
      actionType: ActionType.DOWNLOAD,
      actionLink: format.universalLink,
    };
  }
  return { actionType: ActionType.NO_ACTION };
};

export const mapBookToFormats = (book: BookDetails): BookFormatDetails[] => {
  const {
    title,
    subtitle,
    series,
    bookInSeries,
    summary,
    id,
    uriPath,
    seriesUriPath,
    coverFile,
    displayFile,
  } = book;
  return book.formats
    .filter((entry) => entry.isVisible)
    .map((format) => {
      return {
        book: {
          coverFile,
          displayFile,
          id,
          summary,
          title,
          uriPath,
          bookInSeries,
          series,
          seriesUriPath,
          subtitle,
        },
        format,
        formatAction: findFormatAction({ format }),
      };
    });
};

export const compareDates = ({
  aDateStr,
  bDateStr,
  oldestFirst,
}: {
  aDateStr?: string;
  bDateStr?: string;
  oldestFirst: boolean;
}): number => {
  const aMs = aDateStr ? new Date(aDateStr).valueOf() : 0;
  const bMs = bDateStr ? new Date(bDateStr).valueOf() : 0;
  // for oldestFirst
  // a earlier than b return negative
  // a later then b return positive
  // dates equal return 0
  return oldestFirst ? aMs - bMs : bMs - aMs;
};

export const findActionBookFormat = (book: BookDetails) => {
  // convert the book into array of formats
  const bookFormats = mapBookToFormats(book);
  const matches = bookFormats.filter(
    (format) => format.formatAction.actionType === book.actionType
  );
  if (matches.length > 1) {
    matches.sort((a, b) =>
      compareDates({
        aDateStr: a.format.releaseSchedule?.comingSoonDate,
        bDateStr: b.format.releaseSchedule?.comingSoonDate,
        oldestFirst: false,
      })
    );
  }
  if (matches.length > 0) return matches[0];
  return undefined;
};

export const findPromoDateAndLabel = (
  bookFormat: BookFormatDetails
): { label: string; date: Date } | undefined => {
  const format = bookFormat.format;
  if (!format.releaseSchedule) return undefined;

  const onPreorderLabel = "On pre order from: ";
  const releaseDateLabel = "Release date: ";
  switch (format.releaseStage) {
    case ReleaseStage.PUBLISHED:
      return format.releaseSchedule.publishingDate
        ? {
            label: releaseDateLabel,
            date: new Date(format.releaseSchedule.publishingDate),
          }
        : undefined;
    case ReleaseStage.ON_PREORDER:
    case ReleaseStage.COMING_SOON:
      return format.releaseSchedule.publishingDate
        ? {
            label: releaseDateLabel,
            date: new Date(format.releaseSchedule.publishingDate),
          }
        : format.releaseSchedule.preorderDate
          ? {
              label: onPreorderLabel,
              date: new Date(format.releaseSchedule.preorderDate),
            }
          : undefined;
  }
  return undefined;
};
