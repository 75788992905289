import React from "react";
import { useAppSelector } from "../../lib/hooks";

import { CallToActionBookCard } from "../components/CallToActionBookCard";
import { CallToActionSubscribeCard } from "../components/CallToActionSubscribeCard";
import { selectCallToAction } from "../../lib/selectors";
import { CallToActionType } from "../../lib/types/source-data";
import { CallToActionTeaserCard } from "../components/CallToActionTeaserCard";

// explict to avoid missing something
const shouldShowBookCard = (actionType: CallToActionType): boolean => {
  switch (actionType) {
    case CallToActionType.COMING_SOON:
      return true;
    case CallToActionType.GIFT:
      return true;
    case CallToActionType.NEW_RELEASE:
      return true;
    case CallToActionType.ON_PREORDER:
      return true;
    case CallToActionType.SUBSCRIBE:
      return false;
    case CallToActionType.TEASER:
      return false;
  }
};

const CallToActionContent: React.FC<{ backgroundClass: string }> = (props) => {
  const callToAction = useAppSelector(selectCallToAction);
  if (callToAction.book && shouldShowBookCard(callToAction.actionType))
    return (
      <CallToActionBookCard
        bookDetails={callToAction.book}
        actionType={callToAction.actionType}
        actionLink={callToAction.actionLink}
        backgroundClass={props.backgroundClass}
      />
    );

  switch (callToAction.actionType) {
    case CallToActionType.TEASER:
      return <CallToActionTeaserCard backgroundClass={props.backgroundClass} />;
    default:
      return (
        <CallToActionSubscribeCard backgroundClass={props.backgroundClass} />
      );
  }
};

export interface CallToActionSectionProps {
  backgroundClass: string;
}
const CallToActionSection: React.FC<CallToActionSectionProps> = (props) => {
  return (
    <section>
      <div className="row">
        <CallToActionContent backgroundClass={props.backgroundClass} />
      </div>
    </section>
  );
};

export default CallToActionSection;
