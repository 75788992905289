import React from "react";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { useAppDataLoader, useAppSelector } from "../lib/hooks";
import { AuthorCard } from "./components/AuthorCard";
import { selectAuthor } from "../lib/selectors";

export const AboutPage: React.FC = () => {
  useAppDataLoader();
  const author = useAppSelector(selectAuthor);
  return (
    <div>
      <Header />
      <main>
        <div className="row">
          <AuthorCard
            backgroundClass="background--secondary-washed"
            author={author}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AboutPage;
