export const siteConfig = {
  owner: "Ann Fairway",
  domain: "annfairway.com",
  emailUser: "contact",
  baseUrl: "https://www.annfairway.com/",
  copyright: "2023-2024 by Ann Fairway",
  whitelistLinks: [
    {
      href: "https://clean.email/blog/email-security/how-to-whitelist-an-email",
      title: "Clean Email - How to whitelist an email",
    },
    {
      href: "https://www.whatcounts.com/resources/checklists/how-to-safelist-emails/",
      title: "Whatcounts - How to safelist emails",
    },
  ],
  signup: {
    website: {
      actionUrl:
        "https://assets.mailerlite.com/jsonp/85547/forms/58962181434116095/subscribe",
      formId: "757771",
    },
    withGift: {
      actionUrl:
        "https://assets.mailerlite.com/jsonp/85547/forms/61518531651438314/subscribe",
      formId: "963088",
    },
  },
};
