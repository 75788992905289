import { FETCH_SOURCE_DATA } from "../actions/source-data";
import { WebsiteSourceData } from "../types/source-data";
import { WebsiteData } from "../types/website-types";
import { convertToWebsiteData } from "../actions/utils";

import initialData from "../../data/initial-data-source.json";
// a workaround
const initialState: WebsiteData = convertToWebsiteData(
  initialData as WebsiteSourceData
);

// Reducer
export const websiteDataReducer = (
  state = initialState,
  action: any
): WebsiteData => {
  switch (action.type) {
    case FETCH_SOURCE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
