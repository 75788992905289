import {
  ActionDetails,
  ActionType,
  AuthorDetails,
  BookDetails,
  BookFormat,
  WebsiteData,
} from "../types/website-types";

import {
  WebsiteSourceData,
  BookFormatSource,
  ReleaseSchedule,
  ReleaseStage,
  BookDetailsSource,
  AuthorDetailsSource,
} from "../types/source-data";

const findReleaseStage = ({
  releaseSchedule,
  evaluationDate = new Date(),
}: {
  releaseSchedule?: ReleaseSchedule;
  evaluationDate?: Date;
}): ReleaseStage => {
  if (!releaseSchedule) return ReleaseStage.NOT_FOR_SALE;
  if (
    releaseSchedule.publishingDate &&
    evaluationDate.valueOf() >=
      new Date(releaseSchedule.publishingDate).valueOf()
  )
    return ReleaseStage.PUBLISHED;
  if (
    releaseSchedule.preorderDate &&
    evaluationDate.valueOf() >= new Date(releaseSchedule.preorderDate).valueOf()
  )
    return ReleaseStage.ON_PREORDER;
  return ReleaseStage.COMING_SOON;
};

const findAction = ({ formats }: { formats: BookFormat[] }): ActionDetails => {
  const forSaleFormat = formats.find(
    (format) =>
      format.isVisible && format.releaseStage === ReleaseStage.PUBLISHED
  );
  if (forSaleFormat && forSaleFormat.universalLink) {
    return {
      actionType: ActionType.BUY,
      actionLink: forSaleFormat.universalLink,
    };
  }

  const onpreorderFormat = formats.find(
    (format) =>
      format.isVisible && format.releaseStage === ReleaseStage.ON_PREORDER
  );
  if (onpreorderFormat && onpreorderFormat.universalLink) {
    return {
      actionType: ActionType.PREORDER,
      actionLink: onpreorderFormat.universalLink,
    };
  }

  const isGifttFormat = formats.find(
    (format) => format.isVisible && format.isGift
  );

  if (isGifttFormat && isGifttFormat.universalLink) {
    return {
      actionType: ActionType.DOWNLOAD,
      actionLink: isGifttFormat.universalLink,
    };
  }
  return { actionType: ActionType.NO_ACTION };
};

const isBookFormatVisible = ({
  bookFormat,
  evaluationDate = new Date(),
}: {
  bookFormat: BookFormatSource;
  evaluationDate?: Date;
}): boolean => {
  return (
    evaluationDate.valueOf() >=
    new Date(bookFormat.websiteVisibilityeDate).valueOf()
  );
};

const generateAuthorFullName = ({
  author,
}: {
  author: AuthorDetailsSource;
}): string => {
  if (author.initials) return `${author.initials} ${author.lastName}`;
  return author.middleName
    ? `${author.firstName} ${author.middleName} ${author.lastName}`.trim()
    : `${author.firstName} ${author.lastName}`.trim();
};

export const makeUrlFriendlyBookUriPath = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}): string => {
  if (!title) return "";
  const convertedTitle = title.trim().toLowerCase().replaceAll(" ", "-");
  const convertedSubtitle = subtitle
    ? subtitle.trim().toLowerCase().replaceAll(" ", "-")
    : undefined;
  return convertedSubtitle
    ? `${encodeURIComponent(convertedTitle)}-${encodeURIComponent(
        convertedSubtitle
      )}`
    : `${encodeURIComponent(convertedTitle)}`;
};

export const makeUrlFriendlyBookSeriesUriPath = ({
  series,
}: {
  series?: string;
}): string | undefined => {
  if (!series) return undefined;
  return series.trim().toLowerCase().replaceAll(" ", "-");
};

export const convertToBookFormat = (
  bookFormat: BookFormatSource
): BookFormat => {
  return {
    ...bookFormat,
    isVisible: isBookFormatVisible({ bookFormat }),
    releaseStage: findReleaseStage({
      releaseSchedule: bookFormat.releaseSchedule,
    }),
  };
};

export const convertToBookDetails = (book: BookDetailsSource): BookDetails => {
  const { formats, title, series, subtitle } = book;
  const convertedFormats = formats.map((format) => convertToBookFormat(format));
  return {
    ...book,
    formats: convertedFormats,
    uriPath: makeUrlFriendlyBookUriPath({ title, subtitle }),
    seriesUriPath: makeUrlFriendlyBookSeriesUriPath({ series }),
    ...findAction({ formats: convertedFormats }),
  };
};

export const convertToAuthorDetails = (
  author: AuthorDetailsSource
): AuthorDetails => {
  return {
    ...author,
    fullName: generateAuthorFullName({ author }),
  };
};

export const convertToWebsiteData = (input: WebsiteSourceData): WebsiteData => {
  const {
    callToAction,
    teaser,
    books,
    author,
    subscribe,
    comingSoon,
    giftOffers,
  } = input;
  return {
    config: {
      callToAction,
      subscribe,
      comingSoon,
      teaser,
    },
    books: books.map((book) => convertToBookDetails(book)),
    author: convertToAuthorDetails(author),
    giftOffers,
  };
};
