import React from "react";
import { useAppDataLoader, useAppSelector } from "../lib/hooks";
import { BookCardDetails } from "./components/BookCardDetails";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { selectVisibleBooks } from "../lib/selectors";

export const BooksPage: React.FC = () => {
  useAppDataLoader();
  const books = useAppSelector(selectVisibleBooks);
  return (
    <div>
      <Header />
      <main>
        <div className="row">
          <h1 className="section-title">My books</h1>
        </div>
        {books.map((book) => (
          <div key={book.id} className="row">
            <BookCardDetails
              bookDetails={book}
              backgroundClass="background--light"
            />
          </div>
        ))}
      </main>
      <Footer />
    </div>
  );
};

export default BooksPage;
