import React from "react";

import { AuthorDetails } from "../../lib/types/website-types";

export interface AuthorCardProps {
  author: AuthorDetails;
  backgroundClass: string;
}

export const AuthorCard: React.FC<AuthorCardProps> = (props) => {
  const author = props.author;

  return (
    <div className={"card " + props.backgroundClass}>
      <div className="card__content">
        <div className="card__side">
          <img
            src={author.displayFile}
            alt={author.fullName}
            className="card__picture card__picture--medium"
          />
          <div className="card__block">
            {author.bio.map((line, index) => (
              <p className="card__p" key={index}>
                {line}
              </p>
            ))}
          </div>
          <h2 className="signature">{author.fullName}</h2>
        </div>
      </div>
    </div>
  );
};
