import React from "react";

export interface EmailTextProps {
  user: string;
  domain: string;
}

export const EmailSpan: React.FC<EmailTextProps> = (props) => {
  return (
    <span>
      <span className="u-invisible">###</span>
      {props.user}
      <span className="u-invisible">!!!</span>@{props.domain}
      <span className="u-invisible">###</span>
    </span>
  );
};

export default EmailSpan;
