import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { paths } from "./paths";

const NotFoundPage: React.FC = () => (
  <div>
    <Header />
    <main>
      <div className="row">
        <div className={"card background--light"}>
          <div className="card__content">
            <div className="card__side">
              <h1 className="heading-main">404! - Page Not Found</h1>
              <div>
                <Link
                  className="btn btn--primary btn--animated"
                  to={paths.home}
                >
                  Go Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
);

export default NotFoundPage;
