import React from "react";
import { useAppDataLoader, useAppSelector } from "../lib/hooks";
import Footer from "./components/Footer";
import Header from "./components/Header";
import CallToActionSection from "./home-page/CallToActionSection";
import ComingSoonSection from "./home-page/ComingSoonSection";
import RecentBooksSection from "./home-page/RecentBooksSection";
import SubscribeSection from "./home-page/SubscribeSection";
import { selectComingSoonBooks, selectRecentBooks } from "../lib/selectors";

export const HomePage: React.FC = () => {
  useAppDataLoader();
  const comingSoonLength = useAppSelector(selectComingSoonBooks).length;
  const recentBooksLength = useAppSelector(selectRecentBooks).length;
  return (
    <div>
      <Header />
      <main>
        <CallToActionSection backgroundClass="background--primary-washed" />
        {comingSoonLength > 0 && (
          <ComingSoonSection backgroundClass="background--secondary-washed" />
        )}
        {recentBooksLength > 0 && (
          <RecentBooksSection backgroundClass="background--light" />
        )}
        {/* <SubscribeSection backgroundClass="background--tertiary" /> */}
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
