import React from "react";

import { BookDetails } from "../../lib/types/website-types";
import { findActionBookFormat, findPromoDateAndLabel } from "../utils";

export interface BookCardDetailsProps {
  bookDetails: BookDetails;
  backgroundClass: string;
}

export const BookCardDetails: React.FC<BookCardDetailsProps> = (props) => {
  const book = props.bookDetails;
  const bookFormat = findActionBookFormat(book);

  const promoDateAndLabel = bookFormat
    ? findPromoDateAndLabel(bookFormat)
    : undefined;

  return (
    <div className={"card " + props.backgroundClass}>
      <div className="card__content">
        <div className="card__side">
          <img
            src={book.coverFile}
            alt={book.title}
            className="card__picture card__picture--large"
          />
        </div>
        <div className="card__side">
          <h2 className="book-title u-margin-bottom-medium">{book.title}</h2>

          {book.subtitle && (
            <h3 className="book-subtitle u-margin-bottom-small">
              {book.subtitle}
            </h3>
          )}
          {book.series && (
            <h3 className="book-subtitle u-margin-bottom-small">
              Series:{" "}
              <span className="heading-sub--bold">
                {book.series} - Book {book.bookInSeries}
              </span>
            </h3>
          )}

          <div className="card__block">
            {book.tagLine && (
              <p className="card__p card__p--bold">{book.tagLine}</p>
            )}
            {book.summary.map((line, index) => (
              <p className="card__p" key={index}>
                {line}
              </p>
            ))}
          </div>
          {promoDateAndLabel && (
            <h3 className="card__line u-margin-bottom-small">
              {promoDateAndLabel.label}
              <span className="u-bold-text">
                {promoDateAndLabel.date.toLocaleDateString()}
              </span>
            </h3>
          )}

          {
            book.actionLink && (
              <div>
                <a
                  className="btn btn--primary"
                  target="_blank"
                  href={book.actionLink}
                >
                  {book.actionType}
                </a>
              </div>
            )
            /*           : (
            <div>
              <Link
                className="btn btn--primary btn--animated"
                to={paths.subscribe}
              >
                {ActionType.SUBSCRIBE}
              </Link>
            </div>
          ) */
          }
        </div>
      </div>
    </div>
  );
};
