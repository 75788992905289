import React from "react";

import { Link } from "react-router-dom";
import { paths } from "../paths";
import {
  ActionType,
  BookDetails,
  BookFormatDetails,
} from "../../lib/types/website-types";
import { CallToActionType, ReleaseStage } from "../../lib/types/source-data";
import { mapBookToFormats } from "../utils";
import { compareDates } from "../utils";

export interface CallToactionBookCardProps {
  bookDetails: BookDetails;
  actionType: CallToActionType;
  actionLink?: string;
  backgroundClass: string;
}

const callToActionHeading = (actionType: CallToActionType) => {
  switch (actionType) {
    case CallToActionType.COMING_SOON:
      return "Coming Soon!";
    case CallToActionType.ON_PREORDER:
      return "Preorder Now!";
    case CallToActionType.SUBSCRIBE:
      return "Subscribe Today!";
    case CallToActionType.NEW_RELEASE:
      return "New Release!";
  }
  return "Read Now!";
};

const callToActionPromoDate = (
  actionType: CallToActionType,
  bookFormat: BookFormatDetails
): { label: string; date: Date } | undefined => {
  const format = bookFormat.format;
  if (!format.releaseSchedule) return undefined;

  const onPreorderLabel = "On pre order from: ";
  const releaseDateLabel = "Release date: ";
  switch (actionType) {
    case CallToActionType.COMING_SOON: {
      return format.releaseSchedule.preorderDate
        ? {
            label: onPreorderLabel,
            date: new Date(format.releaseSchedule.preorderDate),
          }
        : format.releaseSchedule.publishingDate
          ? {
              label: releaseDateLabel,
              date: new Date(format.releaseSchedule.publishingDate),
            }
          : undefined;
    }
    case CallToActionType.ON_PREORDER:
      return format.releaseSchedule.publishingDate
        ? {
            label: releaseDateLabel,
            date: new Date(format.releaseSchedule.publishingDate),
          }
        : undefined;
    case CallToActionType.NEW_RELEASE:
      return format.releaseSchedule.publishingDate
        ? {
            label: releaseDateLabel,
            date: new Date(format.releaseSchedule.publishingDate),
          }
        : undefined;
  }
  return undefined;
};

const callToActionBookFormat = (
  actionType: CallToActionType,
  book: BookDetails
) => {
  // convert the book into array of formats
  const bookFormats = mapBookToFormats(book);
  if (actionType === CallToActionType.COMING_SOON) {
    const matches = bookFormats.filter(
      (entry) => entry.format.releaseStage === ReleaseStage.COMING_SOON
    );
    if (matches.length > 1) {
      matches.sort((a, b) =>
        compareDates({
          aDateStr: a.format.releaseSchedule?.comingSoonDate,
          bDateStr: b.format.releaseSchedule?.comingSoonDate,
          oldestFirst: false,
        })
      );
    }
    if (matches.length > 0) return matches[0];
  }

  if (actionType === CallToActionType.ON_PREORDER) {
    const matches = bookFormats.filter(
      (entry) => entry.format.releaseStage === ReleaseStage.ON_PREORDER
    );
    if (matches.length > 1) {
      matches.sort((a, b) =>
        compareDates({
          aDateStr: a.format.releaseSchedule?.preorderDate,
          bDateStr: b.format.releaseSchedule?.preorderDate,
          oldestFirst: false,
        })
      );
    }
    if (matches.length > 0) return matches[0];
  }

  if (actionType === CallToActionType.NEW_RELEASE) {
    const matches = bookFormats.filter(
      (entry) => entry.format.releaseStage === ReleaseStage.PUBLISHED
    );
    if (matches.length > 1) {
      matches.sort((a, b) =>
        compareDates({
          aDateStr: a.format.releaseSchedule?.publishingDate,
          bDateStr: b.format.releaseSchedule?.publishingDate,
          oldestFirst: true, // so we get recent release
        })
      );
    }
    if (matches.length > 0) return matches[0];
  }
  if (actionType === CallToActionType.GIFT) {
    const match = bookFormats.find((entry) => entry.format.isGift);
    if (match) return match;
  }

  // otherwise
  return undefined;
};

export const CallToActionBookCard: React.FC<CallToactionBookCardProps> = (
  props
) => {
  const book = props.bookDetails;
  const cardHeading = callToActionHeading(props.actionType);
  const ctaBookFormat = callToActionBookFormat(props.actionType, book);

  const ctaPromoDate = ctaBookFormat
    ? callToActionPromoDate(props.actionType, ctaBookFormat)
    : undefined;

  const ctaFormatAction = ctaBookFormat
    ? ctaBookFormat.formatAction
    : undefined;

  return (
    <div className={"card " + props.backgroundClass}>
      <div className="card__content ">
        <div className="card__side">
          <h1 className="heading-cta-sub">{cardHeading}</h1>
          <div className="card__main">
            <div className="card__side">
              <img
                src={book.coverFile}
                alt={book.title}
                className="card__picture card__picture--medium"
              />
            </div>
            <div className="card__side">
              <h2 className="heading-main u-margin-bottom-small">
                {book.title}
              </h2>

              {book.subtitle && (
                <h3 className="heading-sub u-margin-bottom-small">
                  {book.subtitle}
                </h3>
              )}
              {book.series && (
                <h3 className="heading-sub u-margin-bottom-small">
                  Series:{" "}
                  <span className="u-bold-text">
                    {book.series} - Book {book.bookInSeries}
                  </span>
                </h3>
              )}

              <div className="card__block">
                {book.tagLine && (
                  <p className="card__p card__p--bold">{book.tagLine}</p>
                )}
                {book.summary.map((line, index) => (
                  <p className="card__p" key={index}>
                    {line}
                  </p>
                ))}
              </div>
              {ctaPromoDate && (
                <h3 className="card__line u-margin-bottom-small">
                  {ctaPromoDate.label}
                  <span className="u-bold-text">
                    {ctaPromoDate.date.toLocaleDateString()}
                  </span>
                </h3>
              )}
              {
                ctaFormatAction && ctaFormatAction.actionLink && (
                  <div>
                    <a
                      className="btn btn--primary"
                      target="_blank"
                      href={ctaFormatAction.actionLink}
                    >
                      {ctaFormatAction.actionType}
                    </a>
                  </div>
                )
                /*           : (
            <div>
              <Link
                className="btn btn--primary btn--animated"
                to={paths.subscribe}
              >
                {ActionType.SUBSCRIBE}
              </Link>
            </div>
          ) */
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
