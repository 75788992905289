import { Dispatch } from "redux";
import { WebsiteData } from "../types/website-types";
import { convertToWebsiteData } from "./utils";

// Action type
export const FETCH_SOURCE_DATA = "FETCH_SOURCE_DATA";

export interface SourceDataActionFetch {
  type: string;
  payload: WebsiteData;
}

// Action creator for fetching data
export const fetchData = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await fetch("../../data/data-source.json");
      const rawData = await response.json();
      const convertedData = convertToWebsiteData(rawData);

      dispatch({
        type: FETCH_SOURCE_DATA,
        payload: convertedData,
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
};
