import React from "react";

import { Link } from "react-router-dom";
import { paths } from "../paths";
import { CallToActionType } from "../../lib/types/source-data";
import { useAppSelector } from "../../lib/hooks";
import { selectSubscribe } from "../../lib/selectors";

export interface CallToActionSubscribeCardProps {
  backgroundClass: string;
}

export const CallToActionSubscribeCard: React.FC<
  CallToActionSubscribeCardProps
> = (props) => {
  const signUp = useAppSelector(selectSubscribe);
  const backgroundClass = props.backgroundClass;
  return (
    <div className={"card " + backgroundClass}>
      <div className="card__content ">
        <div className="card__side">
          <h1 className="heading-cta-sub">More coming soon!</h1>
          <img
            src={signUp.displayFile}
            alt="Subscribe"
            className="card__picture card__picture--fill"
          />

          <div className="card__line">
            <p className="heading-sub">
              Subscribe now so you'll never miss another story
            </p>
            <Link className="btn btn--primary" to={paths.subscribe}>
              {CallToActionType.SUBSCRIBE}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
