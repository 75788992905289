import React from "react";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";

export const CookiePolicyPage: React.FC = () => {
  return (
    <div>
      <Header />
      <main>
        <div className="row">
          <div className="article">
            <h1 className="heading-main">Cookie Policy</h1>
            <p className="article__subtext">
              Effective Date: 25-Dec-2023 <br />
              Last Updated: 25-Dec-2023
            </p>
            <section className="article__section">
              <h2 className="article__subtitle">What are cookies?</h2>
              <p className="article__p">
                This Cookie Policy explains what cookies are and how we use
                them, the types of cookies we use i.e, the information we
                collect using cookies and how that information is used, and how
                to manage the cookie settings.
              </p>
              <p className="article__p">
                Cookies are small text files that are used to store small pieces
                of information. They are stored on your device when the website
                is loaded on your browser. These cookies help us make the
                website function properly, make it more secure, provide better
                user experience, and understand how the website performs and to
                analyze what works and where it needs improvement.
              </p>
            </section>
            <section className="article__section">
              <h2 className="article__subtitle">How do we use cookies?</h2>
              <p className="article__p">
                As most of the online services, our website uses first-party and
                third-party cookies for several purposes. First-party cookies
                are mostly necessary for the website to function the right way,
                and they do not collect any of your personally identifiable
                data.
              </p>
              <p className="article__p">
                The third-party cookies used on our website are mainly for
                understanding how the website performs, how you interact with
                our website, keeping our services secure, providing
                advertisements that are relevant to you, and all in all
                providing you with a better and improved user experience and
                help speed up your future interactions with our website.
              </p>
            </section>
            <section className="article__section">
              <h2 className="article__subtitle">Types of Cookies we use</h2>
              <div className="cky-audit-table-element"></div>
            </section>

            <section className="article__section">
              <h2 className="article__subtitle">Manage cookie preferences</h2>
              <a className="cky-banner-element btn btn--primary">
                Cookie Settings
              </a>
            </section>

            <section className="article__section">
              <p className="article__p">
                You can change your cookie preferences any time by clicking the
                above button. This will let you revisit the cookie consent
                banner and change your preferences or withdraw your consent
                right away.
              </p>
              <p className="article__p">
                In addition to this, different browsers provide different
                methods to block and delete cookies used by websites. You can
                change the settings of your browser to block/delete the cookies.
                Listed below are the links to the support documents on how to
                manage and delete cookies from the major web browsers.
              </p>
              <ul className="article__list">
                <li className="article__item">
                  Chrome:{" "}
                  <a
                    className="article__link"
                    href="https://support.google.com/accounts/answer/32050"
                    target="_blank"
                  >
                    https://support.google.com/accounts/answer/32050
                  </a>
                </li>
                <li className="article__item">
                  Safari:{" "}
                  <a
                    className="article__link"
                    href="https://support.apple.com/en-in/guide/safari/sfri11471/mac"
                    target="_blank"
                  >
                    https://support.apple.com/en-in/guide/safari/sfri11471/mac
                  </a>
                </li>
                <li className="article__item">
                  Firefox:{" "}
                  <a
                    className="article__link"
                    href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US"
                    target="_blank"
                  >
                    https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US
                  </a>
                </li>
                <li className="article__item">
                  Internet Explorer:{" "}
                  <a
                    className="article__link"
                    href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
                    target="_blank"
                  >
                    https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc
                  </a>
                </li>
              </ul>
              <p className="article__p">
                If you are using any other web browser, please visit your
                browser's official support documents.
              </p>
            </section>

            <p className="article__subtext u-margin-bottom-small">
              Cookie Policy Generated By{" "}
              <a
                className="article__link"
                target="_blank"
                href="https://www.cookieyes.com/?utm_source=CP&utm_medium=footer&utm_campaign=UW"
              >
                CookieYes - Cookie Policy Generator
              </a>
              .
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CookiePolicyPage;
