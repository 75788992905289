import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../lib/hooks";
import { BookCoverCard } from "../components/BookCoverCard";
import { paths } from "../paths";
import { selectRecentBooks } from "../../lib/selectors";

const sectionTitle = "Most recent books";
const moreButtonLink = paths.books;
const moreButtonText = "More books...";

const RecentBooksSection: React.FC<{ backgroundClass: string }> = (props) => {
  const books = useAppSelector(selectRecentBooks);
  const max3Books = books.slice(0, 3);
  return (
    <section>
      <div className="row">
        <h2 className="section-title">{sectionTitle}</h2>
      </div>
      <div className="row">
        {max3Books.map((book) => (
          <div key={book.id} className="col-1-of-3">
            <BookCoverCard
              key={book.id}
              bookDetails={book}
              pageLink={`${paths.books}`}
              backgroundClass={props.backgroundClass}
            />
          </div>
        ))}
      </div>
      {/* Only show the button if there are more books than 3 */}
      {books.length > 3 && (
        <div className="row">
          <div className="u-center-text">
            <Link className="btn btn--primary" to={moreButtonLink}>
              {moreButtonText}
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};

export default RecentBooksSection;
