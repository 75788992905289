import {
  ReleaseStage,
  AuthorDetailsSource,
  BookFormatSource,
  Subscribe,
  CallToAction,
  ComingSoon,
  BookSource,
  GiftOffer,
  Teaser,
} from "./source-data";

export enum ActionType {
  NO_ACTION = "NO ACTION",
  SUBSCRIBE = "SUBSCRIBE",
  BUY = "BUY",
  PREORDER = "ORDER",
  DOWNLOAD = "DOWNLOAD",
}

export interface BookFormat extends BookFormatSource {
  isVisible: boolean;
  releaseStage: ReleaseStage;
}

export interface Book extends BookSource {
  uriPath: string;
  seriesUriPath?: string;
}

export interface BookDetails extends Book {
  formats: BookFormat[];
  actionType: ActionType;
  actionLink?: string;
}

export interface ActionDetails {
  actionType: ActionType;
  actionLink?: string;
}

export interface BookFormatDetails {
  book: Book;
  format: BookFormat;
  formatAction: ActionDetails;
}

export interface AuthorDetails extends AuthorDetailsSource {
  fullName: string;
}

export interface WebsiteConfig {
  comingSoon: ComingSoon;
  callToAction: CallToAction;
  subscribe: Subscribe;
  teaser?: Teaser;
}

export interface WebsiteData {
  config: WebsiteConfig;
  author: AuthorDetails;
  books: BookDetails[];
  giftOffers: GiftOffer[];
}
