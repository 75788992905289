import React from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDataLoader, useAppSelector } from "../lib/hooks";
import { BookCardDetails } from "./components/BookCardDetails";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { paths } from "./paths";
import { selectVisibleBooks } from "../lib/selectors";

export const BookNotFoundContent: React.FC = () => (
  <div className="row">
    <div className={"card background--light"}>
      <div className="card__content">
        <div className="card__side">
          <h2 className="heading-sub">
            Can't find the book you are looking for...
          </h2>
          <div>
            <Link className="btn btn--primary btn--animated" to={paths.books}>
              Back to Books
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const BookDetailsPage: React.FC = () => {
  const { bookUri } = useParams();
  useAppDataLoader();
  const books = useAppSelector(selectVisibleBooks);
  const book = books.find((book) => book.uriPath === bookUri);
  return (
    <div>
      <Header />
      <main>
        <div className="row">
          {book ? (
            <BookCardDetails
              bookDetails={book}
              backgroundClass="background--light"
            />
          ) : (
            <BookNotFoundContent />
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

// {props.match.params.item}
export default BookDetailsPage;
