export enum BookFormatType {
  PAPERBACK = "PAPERBACK",
  KINDLE = "KINDLE",
  EBOOK = "EBOOK",
  PDF = "PDF",
}

export enum SocialNetwork {
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  GOODREADS = "GOODREADS",
  TIKTOK = "TIKTOK",
  PINTEREST = "PINTEREST",
  YOUTUBE = "YOUTUBE",
}

export enum CallToActionType {
  NEW_RELEASE = "NEW_RELEASE",
  GIFT = "GIFT",
  COMING_SOON = "COMING_SOON",
  ON_PREORDER = "ON_PREORDER",
  SUBSCRIBE = "SUBSCRIBE",
  TEASER = "TEASER",
}

export enum ReleaseStage {
  NOT_FOR_SALE = "NOT_FOR_SALE",
  COMING_SOON = "COMING_SOON",
  ON_PREORDER = "ON_PREORDER",
  PUBLISHED = "PUBLISHED",
}

export interface ReleaseSchedule {
  preorderDate?: string;
  comingSoonDate?: string;
  publishingDate?: string;
}

export interface BookFormatSource {
  formatType: BookFormatType;
  websiteVisibilityeDate: string;
  isGift?: boolean;
  releaseSchedule?: ReleaseSchedule;
  universalLink?: string;
  isbn?: string; // only important for paperback
  coverFile?: string;
}

export interface BookSource {
  id: string;
  title: string;
  subtitle?: string;
  tagLine?: string;
  summary: string[];
  series?: string;
  bookInSeries?: number;
  coverFile: string;
  displayFile: string;
}

export interface BookDetailsSource extends BookSource {
  formats: BookFormatSource[];
}

export interface SocialHandle {
  socialNetwork: SocialNetwork;
  handle: string;
}

export interface AuthorDetailsSource {
  initials?: string;
  firstName?: string;
  middleName?: string;
  lastName: string;
  bio: string[];
  socialHandles: SocialHandle[];
  displayFile: string;
}

export interface Subscribe {
  displayFile: string;
}

export interface Teaser {
  displayFile: string;
  snippet: string[];
}

export interface GiftOffer {
  code: string;
  bookId: string;
}

export interface CallToAction {
  actionType: CallToActionType;
  actionLink?: string;
  bookId?: string;
}

export interface ComingSoon {
  bookIds: string[];
}

export interface CookieEntryDetails {
  name: string;
  purpose: string;
  provider: string;
  service: string;
  servicePolicyUrl: string;
  country: string;
  type: string;
  installationDate: string;
  validForInMonths: number;
}

export interface WebsiteSourceData {
  callToAction: CallToAction;
  comingSoon: ComingSoon;
  subscribe: Subscribe;
  teaser?: Teaser;
  giftOffers: GiftOffer[];
  author: AuthorDetailsSource;
  books: BookDetailsSource[];
}
