import { BookDetails } from "./types/website-types";

export const sortBooksByPublishingDateDesc = (
  books: BookDetails[]
): BookDetails[] => {
  // Filter out any books without a publishingDate
  const filteredBooks = books.filter((book) =>
    book.formats.some((format) => format.releaseSchedule?.publishingDate)
  );

  // Sort the filtered books by publishingDate in descending order
  const sortedBooks = filteredBooks.sort((a, b) => {
    const aPublishingDate = a.formats.find(
      (format) => format.releaseSchedule?.publishingDate
    )?.releaseSchedule?.publishingDate;
    const bPublishingDate = b.formats.find(
      (format) => format.releaseSchedule?.publishingDate
    )?.releaseSchedule?.publishingDate;

    if (aPublishingDate && bPublishingDate) {
      return (
        new Date(bPublishingDate).getTime() -
        new Date(aPublishingDate).getTime()
      );
    } else if (aPublishingDate) {
      return -1;
    } else if (bPublishingDate) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedBooks;
};
