import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { paths } from "./paths";
import HomePage from "./HomePage";
import BooksPage from "./BooksPage";
import AboutPage from "./AboutPage";
import SubscribePage from "./SubscribePage";
import SubscribeWithGiftPage from "./SubscribeWithGiftPage";

import NotFoundPage from "./NotFoundPage";
import CookiePolicyPage from "./CookiePolicyPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import TermsOfServicePage from "./TermsOfServicePage";
import BookDetailsPage from "./BookDetailsPage";

const router = createBrowserRouter([
  { path: paths.home, Component: HomePage },
  { path: paths.books, Component: BooksPage },
  { path: paths.book, Component: BookDetailsPage },
  { path: paths.about, Component: AboutPage },
  // { path: paths.subscribeWithGift, Component: SubscribeWithGiftPage },
  // { path: paths.subscribe, Component: SubscribePage },
  // { path: paths.comingSoon, Component: ComingSoonPage },
  { path: paths.cookiePolicy, Component: CookiePolicyPage },
  { path: paths.privacyPolicy, Component: PrivacyPolicyPage },
  { path: paths.terms, Component: TermsOfServicePage },
  { path: "*", Component: NotFoundPage },
]);

export default router;
