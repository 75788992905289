import React from "react";

import { NavigationBar } from "./NavigationBar";

export const Header: React.FC = () => (
  <header className="header">
    <div className="row">
      <NavigationBar />
    </div>
  </header>
);

export default Header;
