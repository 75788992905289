import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store"; // Import your root state type
import { sortBooksByPublishingDateDesc } from "./utils";

// Basic selector
export const selectWebsiteData = (state: RootState) => state.websiteData;

export const selectSubscribe = (state: RootState) =>
  state.websiteData.config.subscribe;

export const selectTeaser = (state: RootState) =>
  state.websiteData.config.teaser;

export const selectGiftOffers = (state: RootState) =>
  state.websiteData.giftOffers;

export const selectAuthor = (state: RootState) => state.websiteData.author;

export const selectVisibleBooks = createSelector(
  [selectWebsiteData],
  (data) => {
    // Reorganize your data here
    const visibleBooks = data.books.filter((book) =>
      book.formats.filter((format) => format.isVisible)
    );
    return visibleBooks;
  }
);

export const selectRecentBooks = createSelector(
  [selectVisibleBooks],
  (books) => {
    // Reorganize your data here
    const sortedBooks = sortBooksByPublishingDateDesc(books);
    return sortedBooks;
  }
);

export const selectTitles = (state: RootState) =>
  state.websiteData.books.map((book) => book.title);

export const selectComingSoonBooks = createSelector(
  [selectWebsiteData],
  (data) => {
    const comingSoonIds = data.config.comingSoon.bookIds;
    const comingSoonBooks = data.books.filter((book) =>
      comingSoonIds.find((id) => id === book.id)
    );
    return comingSoonBooks;
  }
);

export const selectCallToAction = createSelector(
  [selectWebsiteData],
  (data) => {
    const callToAction = data.config.callToAction;
    // find selected book
    const callToActionBook = callToAction.bookId
      ? data.books.find((book) => book.id === callToAction.bookId)
      : undefined;

    return {
      book: callToActionBook,
      actionType: callToAction.actionType,
      actionLink: callToAction.actionLink,
    };
  }
);
