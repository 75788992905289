import React from "react";

import { NavLink } from "react-router-dom";
import { EmailSpan } from "./EmailSpan";
import { paths } from "../paths";
import { siteConfig } from "../../site-config";

const menuPaths = [
  {
    path: paths.privacyPolicy,
    display: "Privacy policy",
  },
  {
    path: paths.cookiePolicy,
    display: "Cookie policy",
  },
  {
    path: paths.terms,
    display: "Terms",
  },
];

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="row">
        <div className="col-1-of-3">
          <div className="footer__navigation">
            <ul className="footer__list">
              {menuPaths.map((entry) => (
                <li key={entry.path} className="footer__item">
                  <NavLink
                    className={({ isActive }) =>
                      "footer__link" + (isActive ? " is-active" : "")
                    }
                    end
                    to={entry.path}
                  >
                    {entry.display}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-1-of-3">
          <div className="footer__contact">
            <div>
              <EmailSpan
                domain={siteConfig.domain}
                user={siteConfig.emailUser}
              />
            </div>
          </div>
        </div>
        <div className="col-1-of-3">
          <div className="footer__copyright">
            Copyright &copy; {siteConfig.copyright}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
