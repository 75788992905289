import React from "react";

import { Link } from "react-router-dom";
import { paths } from "../paths";
import { CallToActionType } from "../../lib/types/source-data";
import { useAppSelector } from "../../lib/hooks";
import { selectTeaser } from "../../lib/selectors";
import { BookDetails } from "../../lib/types/website-types";
import NotFoundPage from "../NotFoundPage";

export interface CallToActionTeaserCardProps {
  backgroundClass: string;
}

export const CallToActionTeaserCard: React.FC<CallToActionTeaserCardProps> = (
  props
) => {
  const teaserInfo = useAppSelector(selectTeaser);
  const backgroundClass = props.backgroundClass;
  if (!teaserInfo)
    return (
      <div className={"card " + backgroundClass}>
        <div className="card__content ">
          <h1 className="heading-cta-sub">Loading...</h1>
        </div>
      </div>
    );
  return (
    <div className={"card " + backgroundClass}>
      <div className="card__content ">
        <div className="card__side">
          <img
            src={teaserInfo.displayFile}
            alt="Teaser"
            className="card__picture card__picture--fill"
          />
          <div className="card__block">
            <p className="heading-sub">* * *</p>
            {teaserInfo.snippet.map((line, index) => (
              <p className="card__p" key={index}>
                {line}
              </p>
            ))}
            <p className="heading-sub">* * *</p>
          </div>
          {/* 
          <div className="card__line">
            <p className="heading-sub">
              Subscribe now so you'll never miss another story
            </p>
            <Link className="btn btn--primary" to={paths.subscribe}>
              {CallToActionType.SUBSCRIBE}
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};
