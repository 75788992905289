import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../lib/hooks";
import { BookCardDetails } from "../components/BookCardDetails";
import { paths } from "../paths";
import { selectComingSoonBooks } from "../../lib/selectors";

const sectionTitle = "Coming Soon";
const moreButtonLink = paths.comingSoon;
const moreButtonText = "More books...";

const ComingSoonSection: React.FC<{ backgroundClass: string }> = (props) => {
  const books = useAppSelector(selectComingSoonBooks);
  const max2Books = books.slice(0, 2);
  return (
    <section>
      <div className="row">
        <h2 className="section-title">{sectionTitle}</h2>
      </div>

      {max2Books.map((book) => (
        <div key={book.id} className="row">
          <BookCardDetails
            bookDetails={book}
            backgroundClass={props.backgroundClass}
          />
        </div>
      ))}

      {/* Only show the button if there are more books than 3 */}
      {books.length > 3 && (
        <div className="row">
          <div className="u-center-text">
            <Link className="btn btn--primary" to={moreButtonLink}>
              {moreButtonText}
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};

export default ComingSoonSection;
