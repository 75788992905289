import React from "react";
import { Link } from "react-router-dom";
import { BookDetails } from "../../lib/types/website-types";

export interface BookCoverCardProps {
  pageLink: string;
  bookDetails: BookDetails;
  backgroundClass: string;
}

export const BookCoverCard: React.FC<BookCoverCardProps> = (props) => {
  return (
    <div className={"card card--clickable " + props.backgroundClass}>
      <div className="card__content">
        <div className="card__picture card__picture--fill">
          <Link to={props.pageLink}>
            <img
              src={props.bookDetails.coverFile}
              alt={props.bookDetails.title}
              className="card__picture--fill"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookCoverCard;
